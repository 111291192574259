import './App.css';
import React,{useEffect,useState} from "react";


import AppRouter from './approuter';

const ISDEV = (process.env.REACT_APP_ISDEV=='true') || false;
function App() {
  const [isframed,setIsFramed]=useState(false);
  
  useEffect(()=>{
    if(ISDEV){
      setIsFramed(true);
    }else{
      setIsFramed((window.top!=window.self));
    }
  },[])
  return (
    (isframed)?
    <AppRouter />
    :<></>
  );
}

export default App;
