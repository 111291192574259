import React from 'react';
import { BrowserRouter, Routes, Route, Router, Redirect } from 'react-router-dom';

import DomainInspector from './components/domaininspector';
import FlattenSpf from './components/flattenspf';

function AppRouter(){
    return (
        
        <BrowserRouter>
            <Routes>
                <Route path="/domaininspector" element={<DomainInspector />} />
                <Route path="/flattenspf" element={<FlattenSpf />} />
            </Routes>
        </BrowserRouter>
        
    )
}

export default AppRouter;