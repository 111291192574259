import './dmarcdetail.css';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';


import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';



import React, {
  useState,
  useEffect,
} from "react";
import { ButtonGroupButtonContext } from '@mui/material';



const DmarcDetail = (props)=>{

    const [dmarc,setDmarc]=useState();
    const [open, setOpen]=useState(false);

    useEffect(()=>{
        setDmarc(props.dmarc);
    },[props.dmarc]);

    return (dmarc)?
        
             <Container>
                <ListItemButton sx={{ userSelect: 'unset' }} onClick={(event)=>setOpen(!open)}>
                    <Box  sx={{ display: 'flex',
                                justifyContent: 'space-between',
                                width:'100%'
                                }}>
                        <Box><h2>DMARC Configuration</h2></Box>
                        <Box>{(open) ? <ExpandLess />:<ExpandMore />}</Box>
                    </Box>
                </ListItemButton>
                
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Stack direction="row" style={{width:'100%'}}>
                        <div className="dmarccontentitem" style={{width:'33%'}}>
                            <div className="dmarccontentheading">Policy:</div>
                            <div className="dmarccontent">{dmarc.action||'not configured'}</div>
                        </div>
                        <div  className="dmarccontentitem" style={{width:'33%'}}>
                            <div className="dmarccontentheading">Aggregate Reports:</div>
                            {(dmarc.rua)?dmarc.rua.map((rua)=><Box className="dmarccontent">{rua}</Box>):<Box className="dmarccontent">not configured</Box>}
                        </div>
                        <div  className="dmarccontentitem" style={{width:'33%'}}>
                            <div className="dmarccontentheading">Forensic Reports:</div>
                            {(dmarc.ruf)?dmarc.ruf.map((ruf)=><Box className="dmarccontent">{ruf}</Box>):<Box className="dmarccontent">not configured</Box>}
                        </div>
                    </Stack>
                </Collapse>
                
            </Container>
        
    :<div />
}
export default DmarcDetail;