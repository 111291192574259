import './flattenspflanding.css';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import React from "react";




const FlattenSpfLanding = (props)=>{

    return (
             <Container sx={props.sx}>
                <h1>SPF Record Flattening</h1>
                <Box className="content">SPF Record flattening enumerates all references within the specified domain's SPF record and constructs static representations in a nested format.</Box>
                <Box className="content">Although inferior to SPF Macro Expansion, SPF Flattening may be used to reduce the number of SPF lookups initiated during SPF record evaluation.</Box>
                <Box className="content">You may opt to use SPF Flattening to provide a quick correction for exceeding the SPF 10 Lookup Limit, but it should generally be thought of as a short-term fix versus a long-term solution.</Box>
                <Box className="content">Simply enter a domain name in the input box and click the "Flatten" button.</Box>
            </Container>
            )
        
    
}
export default FlattenSpfLanding