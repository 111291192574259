/*global AwsWafIntegration*/
/*global AwsWafCaptcha*/

const Cookies = require('universal-cookie');

const APIGWKEY = process.env.REACT_APP_APIGWKEY;
const getProtected = async(url)=>{
  
    return new Promise(async(resolve,reject)=>{
        let retobj;
        try{
            const cookies = new Cookies(null, { path: '/' });
            
            //await loadCaptchaScript();
            let token = await AwsWafIntegration.getToken();
            const res = await AwsWafIntegration.fetch(url,{headers:{"x-spfxio-tools-viewer":cookies.get('spfxio-tools-viewer')}})
            switch(res.status){
                case 405:
                    let container = document.getElementById("captcharoot");
                    if(!container){
                        container = document.createElement("div");
                        container.id="captcharoot";
                        document.body.insertBefore(container, document.body.firstChild);
                    }

                    let captcha = await getCaptcha(container);
                    
                    
                    if(captcha){
                        retobj = await this.getProtected(url);
                        
                    }
                
                break;
                case 429:
                    reject({code:429,message:'too many requests'});
                break;
                case 500:
                    try{
                        let errobj = await res.json()
                        reject({code:500,message: errobj.err||'internal server error'});
                    }catch{
                        reject({code:500,message:'internal server error'});
                    }
                    
                break;
                case 202:
                    console.log('challenge not fulfilled');
                break;
                case 200:
                    let viewervalue = res.headers.get('x-spfxio-tools-viewer');
                    if(viewervalue){
                        cookies.set('spfxio-tools-viewer',viewervalue,{path:'/',maxAge:((new Date()).setDate((new Date().getDate()+365))), secure:true,sameSite:'lax'});
                    }
                    retobj = await res.json();
                break;
                default:
                    console.log('protectedRequests.getProtected default case');
                    reject(res);
                break;

            }

            resolve(retobj);

            
        }catch(err){
            console.log('outer catch');
            reject(err);
        }
    })
}



const getCaptcha = (container)=>{
    return new Promise((resolve,reject)=>{

    
        let options = {
            apiKey: APIGWKEY,
            onSuccess: (wafToken)=>{
                setTimeout(()=>{
                    container.style.display='none';
                },1000);
                
                //el.removeChild(el.firstChild);
                resolve(wafToken);
            
            },
            onError: (wafError)=>{
                console.error('captcha error',wafError);
                reject(wafError);
            },
        }
        
        AwsWafCaptcha.renderCaptcha(container.firstChild,options);
        container.style.display='block';
        

    })
}

module.exports = { getProtected }