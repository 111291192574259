import './domaininspector.css';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';

import React, {
  useState,
  useEffect,
} from "react";

import DomainHint from './domainhint';


const DomainHints = (props)=>{

    const [result,setResult]=useState();

    useEffect(()=>{
        let data = props.result;
        if(data){
            data = addConfigHints(data.hostname,data);
            setResult(data);
        }
        

        
    },[props.result]);


    const addConfigHints = (domain,result)=>{
        
        if(result){  
            result.confighints=[];
            
            let infinite = false

            let isInfinite = (record)=>{
                infinite|=record.includesself;
                record.children.map((c)=>isInfinite(c));
            }
            isInfinite(result.spf);
            
            if(infinite){
                result.confighints.push({
                    severity: 1
                  , elements: <DomainHint icon="error">SPF permits up to 10 name lookups during SPF authentication.  Your SPF record, or included record, creates an infinite loop by including itself or a parent.  This infinite loop causes the 10 Lookup limit to be exceeded.  Exceeding the lookup limit results in a permanent error during SPF authentication that may impact email delivery while increasing risk of fraudulent or spoofed emails reaching recipients.  Review the SPF Configuration section below to see which record causes an infinite loop.</DomainHint>
                              
                })    
            }

            if(result.mxlookupexceeded){
                result.confighints.push({
                    severity: 1
                  , elements: <DomainHint icon="error">SPF MX mechanisms may return up to 10 named hosts during SPF authentication.  Your SPF record, or included record, exceeds the MX reference limitation.  This results in permanent errors during SPF authentication.    Permanent errors significantly increase risk of fraudulent or spoofed emails being accepted by receiving email gateways.</DomainHint>
                              
                })    
            }
            
            let numspfrecords = result.spf.spfrecords;
            if(numspfrecords>1){
                result.confighints.push({
                          severity: 1
                        , elements: <DomainHint icon="error">Only 1 SPF record is permitted per domain.  Your domain currently has { numspfrecords } configured.  This results in permanent errors during SPF authentication.  Permanent errors significantly increase risk of fraudulent or spoofed emails being accepted by receiving email gateways.</DomainHint>
                                    
                })
            }

            let packets = result.spf.packets;
            if(packets>1){
                result.confighints.push({
                    severity:2
                    , elements: <DomainHint icon="warning">Your SPF record length exceeds UDP protocol limits.  Querying systems attempt initial SPF record lookups using UDP.  When UDP packet length is exceeded, the querying system must switch to TCP.  The additional network traffic and complexity increases the risk of errors during SPF authentication.</DomainHint>
                })
            }
            
            let defaultspf = result.spf.defaultaction;//filter((r)=>{ return (domain===r.hostname)}).length===1)?result.spf.filter((r)=>{ return (domain===r.hostname)})[0].defaultaction:'-';
            switch(defaultspf){
                case "fail":
                
                break;
                case "softfail":
                    result.confighints.push({
                              severity: 2
                            , elements: <DomainHint icon="warning">Your SPF default authentication response may be further strengthened by changing from [softfail] to [fail].</DomainHint>
                                        
                    })
                break;
                case "neutral":
                    result.confighints.push({
                              severity: 2       
                            , elements: <DomainHint icon="warning">Your domain's SPF record is configured to return a [neutral] authentication result by default.  The [neutral] default response increases risk that fraudulent or spoofed emails may be accepted by receiving email gateways.  Your SPF default authentication response may be further strengthened by changing from [neutral] to [softfail] or [fail].</DomainHint>
                                        
                        
                    })
                break;
                case "pass":
                    result.confighints.push({
                              severity: 2        
                            , elements: <DomainHint icon="warning">Your domain's SPF record is configured to return a [pass] authentication result by default.  The [pass] default response authorizes every IP address to send email on behalf of your domain.  This significantly increases risk that fraudulent or spoofed emails may be accepted by receiving email gateways.  Your SPF default authentication response may be further strengthened by changing from [pass] to [neutral] or [softfail] or [fail].</DomainHint>
                                        
                        
                    })
                break;
                default:
                    result.confighints.push({
                              severity: 1       
                            , elements: <DomainHint icon="error">Your domain's SPF record was not identified or may not be properly configured.  Verify your SPF record.</DomainHint>
                                        
                        
                    })
                break;
            
            }   
            
            let spflookupcount = result.lookupcount;
            switch(true){
                case (spflookupcount>10):
                    result.confighints.push({
                              severity: 1
                            , elements: <DomainHint icon="error">
                                            SPF permits up to 10 name lookups during SPF authentication.  Your SPF record exceeds that limit with <b>{spflookupcount} lookups</b>.  Exceeding the lookup limit causes a permanent error during SPF authentication that may impact email delivery while increasing risk of fraudulent or spoofed emails reaching recipients.  Review the SPF Configuration section below to see how the lookup limit is exceeded.
                                        </DomainHint>
                                            
                        
                    })
                break;
                case (spflookupcount>=7):
                    result.confighints.push({
                              severity: 2     
                            , elements: <DomainHint icon="warning">SPF permits up to 10 name lookups to be completed during SPF authentication.  Your SPF configuration initiates {spflookupcount } name lookups.  Modifications to third-party referenced records may result in exceeding the lookup limit for your domain and cause permanent errors during SPF authentication.  Exceeding the lookup limit significantly increases risk that fraudulent or spoofed emails may be accepted by receiving email gateways.</DomainHint>
                    })
                break;
            }
            
            let spflookuperrors = result.spflookuperrors;
            if(spflookuperrors>0)
            {
                result.confighints.push({
                              severity: 1     
                            , elements: <DomainHint icon="error">SPF lookup errors were identified.  This often occurs due to hostname configuration, SPF mechanism misuse, or as a result of macro expansion during testing of your record.  Review errors below to identify any required actions.</DomainHint>
                    })
            }
            
            let dmarcaction = result.dmarc.action;
            switch(dmarcaction){
                case "reject":
                
                break;
                case "quarantine":
                    result.confighints.push({
                              severity: 3      
                            , elements: <DomainHint icon="info">Your domain's DMARC configuration publishes a [quarantine] default action policy, specifying that emails failing DMARC authentication should be quarantined.  Your DMARC configuration may be further strengthened by moving from [quarantine] policy to a [reject] policy.</DomainHint>
                    })
                break;
                case "none":
                    result.confighints.push({
                              severity: 3         
                            , elements: <DomainHint icon="info">Your domain's DMARC configuration publishes a [none] policy.  Your DMARC configuration may be further strengthened by moving from a [none] policy to a [quarantine] or [reject] policy.</DomainHint>
                    })
                break;
                default:
                        
                result.confighints.push({
                              severity: 2         
                            , elements: <DomainHint icon="warning">A DMARC configuration was not identified for your domain.</DomainHint>
                    })
                break;
            
            }
            
            let rua = result.dmarc.rua;
            let ruf = result.dmarc.ruf;
            let rxmailto = new RegExp(/^mailto:.+$/);
                 
            if(!rxmailto.exec(rua)){
                result.confighints.push({
                              severity: 2
                            , elements: <DomainHint icon="warning">A valid DMARC RUA destination was not identified.</DomainHint>
                    })
                
            }
            if(!rxmailto.exec(ruf)){
                result.confighints.push({
                              severity: 2
                            , elements: <DomainHint icon="info">A valid DMARC RUF destination was not identified.</DomainHint>
                    })
                
            }
        
        }  
        return result;
    }

   

    return (result)?
        <div className="confighints">
            {result?.confighints?.sort((a,b)=> a.severity-b.severity).map((hint) =>
                <div class="icon-ac-wrap pr-20" >
                        {hint.elements}
                </div>
            )}
        </div>
    :<div />
}
export default DomainHints;