import './domaininspector.css';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';

import React, {
  useState,
  useEffect,
} from "react";


const DomainHint = (props)=>{

    
    
    const IconSelector = (props) =>{
        let iconelement;
        switch(props.icon){
            case "error":
                iconelement = <ErrorIcon className="confighinterror" />
            break;
            case "warning":
                iconelement = <WarningIcon className="confighintwarning" />
            break;
            default:
                iconelement = <InfoIcon className="confighintinfo" />
            break;
        }
        return iconelement;
        }


    return (props)?
    <Stack className="confighintstack" direction="row">
        <Box className="confighinticon"><IconSelector icon={props?.icon} /></Box>
        <Box className="confighintitem">{props?.message||props?.children}</Box>
    </Stack>
    :<></>
    
}
export default DomainHint