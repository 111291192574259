//react components
import React, {
    useState,
  } from "react";

//third-party components
import Drawer from '@mui/material/Drawer';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import ErrorIcon from '@mui/icons-material/Error';

//custom libraries
import { domainInspector } from '../libs/spfxioapi';
import {remove} from '../libs/ArrayHelper';

//custom components
import DomainResult from './domainresult.js';
import DomainInspectorLanding from "./domaininspectorlanding.js";

//stylesheets
import './domaininspector.css';





const DomainInspector = (props)=>{

    const [domain, setDomain] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [spfresults,setSpfResults]=useState([]);
    const [open, setOpen] = React.useState(false);
    const [openchanged,setOpenChanged]=React.useState(1);
    const [spfopenstate,setSpfOpenState]=useState([]);
    const [errormessage,setErrorMessage]=useState();
    

    const loadAnalysis=async()=>{
        setIsLoading(true);
        try{

            let data = await domainInspector(domain);
            if(data){
                let tres = spfresults;
                tres.unshift(data);
                setSpfResults(tres);
                setDomain('');
                if(data.score.grade=='F'){
                    //setOpen(true);
                }
            }
        }catch(err){
            switch(err.code){
             case 429:
                setErrorMessage("You've submitted too many requests.  Please wait before trying again.");
             break;
             default:
                if(err.message){
                    setErrorMessage(err.message);
                }else{
                    setErrorMessage('unspecified error.  please try again');
                }
             break;
            }
            

            setTimeout(()=>{
                console.log('timeout triggered');
                setErrorMessage();
                
            },5000);
        }
      setIsLoading(false);
    }
  
  
    const removeItem = (item)=>{
        //let results = spfresults;
        setSpfResults(remove(spfresults,spfresults.indexOf(item),1));

    }
  
  
    return (
        <>
            <Container fullWidth="true">
             
            {(errormessage&&errormessage.length>0)?<Alert icon={<ErrorIcon fontSize="inherit" />} severity="error">{errormessage}</Alert>:<></>}
            <Stack direction="row">
            <TextField
                    required
                    id="outlined-required"
                    name="domain"
                    fullWidth
                    margin="normal"
                    label="Domain"
                    value={domain}
                    onChange={(event)=>{setDomain(event.target.value)}}
                    onKeyDown={(key)=>{(key.keyCode===13)?(loadAnalysis()):void(0)}}
                    
                    />
            <LoadingButton
                onClick={loadAnalysis}
                endIcon={<SendIcon />}
                loading = {isLoading} 
                loadingPosition="end"
                variant="contained"
                sx={{height:'55px',marginTop:'16px'}}
                >Inspect</LoadingButton>
            </Stack>
            </Container>
            <Container className={(spfresults.length>=1)? "spfresultscontainer":"spfresultscontainer-hidden"} sx={{borderRadius:'16px'}}>
                {(spfresults)?
                    spfresults.map((r)=>
                    <DomainResult result={r} removeItem={removeItem} />
                    )
                    :<></>
                }
                
            </Container>
            {(spfresults.length==0)?
                <DomainInspectorLanding></DomainInspectorLanding>
                :
                <Drawer
                    anchor="right"
                    open={open}
                    onClose={()=>{setOpen(!open)}}
                >
                    <DomainInspectorLanding sx={{width:'250px'}}></DomainInspectorLanding>
                </Drawer>
            }
        </>
    )
}

export default DomainInspector;