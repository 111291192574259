
import './domaininspector.css';
import './spfexplorer.css';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import CloseIcon from '@mui/icons-material/Close';

import { remove } from '../libs/ArrayHelper';



import React, {
  useState,
  useEffect,
} from "react";



const SpfExplorer = (props)=>{

    const [domain, setDomain] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [spfresults,setSpfResults]=useState([]);
    const [panelopen, setPanelOpen] = React.useState([]);
    const [openchanged,setOpenChanged]=React.useState(0);
    const [spfopenstate,setSpfOpenState]=useState([]);
    const [errormessage,setErrorMessage]=useState();

    useEffect(()=>{
        let data = props.spfresults;
        
        
        let defaultspfopenstate = spfopenstate;

            let issuekey = (record)=>{
                record.key = crypto.randomUUID();
                defaultspfopenstate[record.key]=false
                record.children.forEach(c=>issuekey(c));
                
            }

            issuekey(data);
            
            setSpfOpenState(defaultspfopenstate);
            
            let openstate = panelopen;
            setPanelOpen(false);
            setDomain(props.hostname);
            setSpfResults([data]);
        

    },[props.spfresults,props.hostname]);

    

    const renderspfrecordtree=(hostname, spfrecords, rootonly,rootindex)=>{
            
        
        let getpadding=(depth)=>{
           //return { "padding-left" : (depth*20)+'px'};
           return ((depth*20)+'px').toString();
        }
        
        let togglerecord = (key)=>{
    
            let openstate = spfopenstate;
            if(openstate[key]==undefined){
                openstate[key]=true;
            }else{
                openstate[key]=!openstate[key];
            }
            setSpfOpenState(openstate);
            setOpenChanged(Math.random()*1000);
        }

        
         return spfrecords.map(
                   (record,i) =>
                   
                           <List sx={{paddingLeft:getpadding(record.nestdepth)}} >
                                           
                                                <Box className="spfrecordbox" sx={{borderRadius:'16px'}}>
                                                    
                                                        {(record.children.length>0 )?
                                                            <>
                                                             
                                                                <ListItemButton id={record.key} sx={{ userSelect: 'unset' }} onClick={(event)=>togglerecord(record.key)}>
                                                                  
                                                                    <Box sx={{ display: 'flex',
                                                                                justifyContent: 'space-between',
                                                                                width:'100%'
                                                                                }}>
                                                                        <Box className="wordbreakable"><h3>{record.hostname}</h3></Box>
                                                                        <Box>{((openchanged&&spfopenstate[record.key])||spfopenstate[record.key]==undefined) ? <ExpandLess recordkey={record.key} />:<ExpandMore recordkey={record.key} />}</Box>

                                                                    </Box>   
                                                                
                                                                </ListItemButton>
                                                                
                                                            
                                                            <Box>{record.record}</Box>
                                                            <Box>Lookup Count: {record.numlookups}</Box>
                                                            <Box>IPv4 Ranges: {record.ranges.length}</Box>
                                                            <Box>IPv6 Ranges: {record.ip6ranges.length}</Box>
                                                            
                                                            
                                                            {record.errors.map((error)=><Box className="spferrorchild">Error:  { JSON.stringify(error) }</Box>)}
                                                            <Collapse in={((openchanged&&spfopenstate[record.key])|| spfopenstate[record.key==undefined] )} timeout="auto" unmountOnExit>
                                                                {(record.mxrecords?.length>0)?
                                                                <Box className="mxrecordbox" sx={{borderRadius:'16px',paddingLeft:getpadding(record.nestdepth+1)}}>
                                                                    <Box class="mxrecordheading"><h3>mx</h3></Box>
                                                                {(record.mxrecords?.map((mx)=>
                                                                      <Box class="mxrecord">{mx.exchange}</Box>
                                                                ))}
                                                                </Box>
                                                                :<></>
                                                                }
                                                                { (record.parentrecord !== record.hostname || (rootonly===true))?renderspfrecordtree(record.hostname,record.children,false,rootindex):''}
                                                            </Collapse>
                                                            </>
                                                        :
                                                            <>
                                                                <h3>{record.hostname}</h3> 
                                                                <div>{record.record}</div>
                                                                <div>Lookup Count: {record.numlookups}</div>
                                                                <Collapse in={((openchanged&&spfopenstate[record.key])|| spfopenstate[record.key==undefined] )} timeout="auto" unmountOnExit>
                                                                {(record.mxrecords?.length>0)?
                                                                    <Box className="mxrecordbox" sx={{borderRadius:'16px',paddingLeft:getpadding(record.nestdepth+1)}}>
                                                                        <Box class="mxrecordheading"><h3>mx</h3></Box>
                                                                    {(record.mxrecords?.map((mx)=>
                                                                        <Box class="mxrecord">{mx.exchange}</Box>
                                                                    ))}
                                                                    </Box>
                                                                    :<></>
                                                                    }
                                                                </Collapse>
                                                                {record.errors.map((error)=><div className="spferror">Error:  { JSON.stringify(error) }</div>)}
                                                            </>
                                                        }
                                                        
                                                    
                                                
                                                
                                                </Box>
                                           
                                       
                                   
                           </List>
                    
       )
      }

      let i=0;

     return ((spfresults)?
            <Container>
                <Stack direction="column" style={{marginTop:'20px'}}>
                    <Stack direction="row">
                        
                        <ListItemButton  onClick={()=>{
                                let openstate = panelopen;
                                setPanelOpen(!panelopen);
                                setOpenChanged(Math.random()*1000);
                            }}
                        >
                            
                            

                            <Box  sx={{  display: 'flex',
                                                justifyContent: 'space-between',
                                                width:'100%'
                                                }}>
                                <Box><h2>SPF Configuration</h2></Box>
                                <Box>{(openchanged&&panelopen) ? <ExpandLess /> : <ExpandMore />}</Box>
                            </Box>   


                            
                        </ListItemButton>
                    </Stack>
                    <Collapse in={(openchanged && panelopen)} timeout="auto" unmountOnExit>
                        <Box className="spfcontainer">
                            <Button onClick={()=>{
                                
                                let elstate = spfopenstate;
                                let parentstate = elstate[spfresults[0].key];
                                
                                let togglerecord = (record)=>{
                                    record.key = crypto.randomUUID();
                                    elstate[record.key]=!(parentstate)
                                    record.children.forEach(c=>togglerecord(c));
                                }
                                
                                //spfresults.forEach(s=>elstate[s.key]=!parentstate);
                                togglerecord(spfresults[0]);
                                setSpfOpenState(elstate);
                                setOpenChanged(Math.random()*1000);
                                }}>Toggle Records</Button>
                        
                            {renderspfrecordtree(domain,spfresults,true,i)}
                        </Box>
                    </Collapse>
                </Stack>
            </Container>
            :<></>
              
            
              
        );
}

export default SpfExplorer;