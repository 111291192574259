import { getProtected } from '../libs/protectedRequests';

const APIGW=process.env.REACT_APP_APIGW||'';


const domainInspector = async(domain)=>{
    return new Promise(async (resolve,reject)=>{

        if(!domain.match(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g)){
            reject({"code":"500","message":"invalid domain name"});
        }else{

            try{
                const data = await getProtected(`${APIGW}/tools/domaininspector/${domain}`);
                resolve(data);
            }catch(err){
                console.log('error caught in spfxioapi.domainInspector',err);
                console.dir(err,{depth:null});
                reject(err);
            }
        }
    })
   
}

const flattenSpf = async(domain)=>{
    return new Promise(async (resolve,reject)=>{

        if(!domain.match(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g)){
            reject({"code":"500","message":"invalid domain name"});
        }else{

            try{
                const data = await getProtected(`${APIGW}/tools/flattenspf/${domain}`);
                resolve(data);
            }catch(err){
                console.log('error caught in spfxioapi.flattenSpf',err);
                console.dir(err,{depth:null});
                reject(err);
            }
        }
    })
}

export {domainInspector,flattenSpf}