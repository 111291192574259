import './domaininspector.css';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';



import React, {
  useState,
  useEffect,
} from "react";


const DomainScore = (props)=>{

    const [score,setResult]=useState();

    useEffect(()=>{
        setResult(props.score);
    },[props.score]);


    return (score)?
        <div>
             <Stack direction="row" className="gradecontainer">
                <div className={(score.grade==='F')?'grade grade_f grade-selected':'grade grade_f'}>F</div>
                <div className={(score.grade==='D')?'grade grade_d grade-selected':'grade grade_d'}>D</div>
                <div className={(score.grade==='C')?'grade grade_c grade-selected':'grade grade_c'}>C</div>
                <div className={(score.grade==='B')?'grade grade_b grade-selected':'grade grade_b'}>B</div>
                <div className={(score.grade==='A')?'grade grade_a grade-selected':'grade grade_a'}>A</div>
                <div className={(score.grade==='A+')?'grade grade_aplus grade-selected':'grade grade_aplus'}>A+</div>
            </Stack>
        </div>
    :<div />
}
export default DomainScore