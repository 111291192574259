import './flattenspfresult.css';
import Container from '@mui/material/Container';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button'
import { Icon, IconButton } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';


import React, {
  useState,
  useEffect,
} from "react";



const FlattenSpfResult = (props)=>{

    const [result,setResult]=useState();
    const [open, setOpen]=useState();

    useEffect(()=>{
        setResult(props.result);
        setOpen(true);

        
    },[props.result]);

    
    const removeresultitem = async (event,index) =>{
        if(props.removeItem){
            props.removeItem(result);
        }
    }
    

    return (result)?
        <Container className="domainresultcontainer" sx={{borderRadius:'16px'}}>
            <Box  sx={{ display: 'flex',
                                justifyContent: 'flex-end',
                                width:'100%'
                                }}><IconButton onClick={removeresultitem}><CloseIcon /></IconButton>
            </Box>

            <ListItemButton sx={{ userSelect: 'unset' }} onClick={(event)=>setOpen(!open)}>
                <Box  sx={{ display: 'flex',
                            justifyContent: 'space-between',
                            width:'100%'
                            }}>
                    <Box><h1>{result.domain}</h1></Box>
                    <Box>{(open) ? <ExpandLess />:<ExpandMore />}</Box>
                </Box>
            </ListItemButton>

            <Collapse in={open} timeout="auto" unmountOnExit>
            {
                result.records.map((r)=>
                <Stack direction="row" className="recordrows">
                    <Box className="hostname">{r.hostname}</Box>
                    <Box className="recordtype">TXT</Box>
                    <Box className="record">{r.record}</Box>
                </Stack>
                )
            }
            </Collapse>
            
             
            
            
        </Container>
    :<div />
}
export default FlattenSpfResult;