import './domaininspectorlanding.css';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';


import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';



import React, {
  useState,
  useEffect,
} from "react";
import { ButtonGroupButtonContext } from '@mui/material';



const DomainInspectorLanding = (props)=>{

    return (
             <Container sx={props.sx}>
                <h1>Domain Inspector</h1>
                <Box className="content">Our Domain Inspector provides a real-time view of a domain's email authentication configuration, detects common issues, and offers corrective guidance.</Box>
                <Box className="content">Simply enter a domain in the input box, and click the "Inspect" button.</Box>
                <Box className="content">Results will be available to you immediately.</Box>
            </Container>
            )
        
    
}
export default DomainInspectorLanding